import { splashScreen, windowScroll, galleryCorousel, gallery, animateGallery, animateCloseGallery, image_holder, imagesAnimation } from "./anim";

//gallery images
import gallery1 from './images/gallery/gallery_set/gallery_1.webp'
import gallery2 from './images/gallery/gallery_set/gallery_2.webp'
import gallery3 from './images/gallery/gallery_set/gallery_3.webp'
import gallery4 from './images/gallery/gallery_set/gallery_5.webp'
import gallery5 from './images/gallery/gallery_set/gallery_6.webp'
import gallery6 from './images/gallery/gallery_set/gallery_7.webp'
import gallery7 from './images/gallery/gallery_set/gallery_8.webp'
import gallery8 from './images/gallery/gallery_set/gallery_9.webp'
import gallery9 from './images/gallery/gallery_set/gallery_10.webp'
import gallery10 from './images/gallery/gallery_set/gallery_11.webp'
import gallery11 from './images/gallery/gallery_set/gallery_12.webp'
import gallery12 from './images/gallery/gallery_set/gallery_13.webp'
import gallery14 from './images/gallery/gallery_set/gallery_14.webp'
import gallery15 from './images/gallery/gallery_set/gallery_15.webp'

import curate1 from './images/gallery/curated/curated_itenaries_1.webp'
import curate2 from './images/gallery/curated/curated_itenaries_2.webp'
import curate3 from './images/gallery/gallery_set/gallery_4.webp'
import curate4 from './images/gallery/curated/curated_itenaries_3.webp'

//reserve
import reserve1 from './images/gallery/reservation/reservation_1.webp'
import reserve2 from './images/gallery/reservation/reservation_2.webp'
import reserve3 from './images/gallery/gallery_set/gallery_12.webp'

//transport
import transport1 from './images/gallery/transport/transport_1.webp'
import transport2 from './images/gallery/transport/transport_2.webp'
import transport3 from './images/gallery/transport/transport_3.webp'

//private guide
import private_guide1 from './images/gallery/private_tour/private_tour_1.webp'
import private_guide2 from './images/gallery/private_tour/private_tour_2.webp'
import private_guide3 from './images/gallery/private_tour/private_tour_3.webp'

//photography
import photography1 from './images/gallery/photography/photography_1.webp'
import photography2 from './images/gallery/photography/photography2.webp'
import photography3 from './images/gallery/photography/photogaphy_3.webp'

//Dining
import dining1 from './images/gallery/dining/private_dining_1.webp'
import dining2 from './images/gallery/dining/private_dining_2.webp'
import dining3 from './images/gallery/dining/private_dining_3.webp'

//Exclusive
import exclusive1 from './images/gallery/exclusives/exclusive_1.webp'
import exclusive2 from './images/gallery/exclusives/eclusive_2.webp'
import exclusive3 from './images/gallery/exclusives/exclusive_3.webp'

//Shopping
import shopping1 from './images/gallery/shopping/shopping_1.webp'
import shopping2 from './images/gallery/shopping/shopping_2.webp'
import shopping3 from './images/gallery/shopping/shopping_3.webp'

//Conciege
import conciege1 from './images/gallery/conciege/concieg_1.webp'
import conciege2 from './images/gallery/conciege/conciege_2.webp'
import conciege3 from './images/gallery/conciege/conciege_3.webp'


const gallery_set = [
    private_guide2,
    gallery2,
    gallery8,
    gallery9,
    gallery3,
    gallery4,
    gallery6,
    gallery7,
    curate1,
    curate3,
    reserve1,
    reserve2,
    transport1,
    private_guide3,
    curate2,
    photography3,
    dining1,
    dining3,
    gallery14,
    curate4,
    exclusive1,
    exclusive2,
    exclusive3,
    shopping3,
    conciege2,
    gallery15,

    private_guide2,
    gallery2,
    gallery8,
    gallery9,
    gallery3,
    gallery4,
    gallery6,
    gallery7,
    curate1,
    curate3,
    reserve1,
    reserve2,
    transport1,
    private_guide3,
    curate2,
    photography3,
    dining1,
    dining3,
    gallery14,
    curate4,
    exclusive1,
    exclusive2,
    exclusive3,
    shopping3,
    conciege2,
    gallery15,
]

const total_index = document.querySelector('.total_images')
total_index.textContent = gallery_set.length / 2


//Run Functions here...
splashScreen()
windowScroll()
galleryCorousel()

const close_button = document.querySelector('.close_button')
const overlay = document.querySelectorAll('.overlay')
const img_index = document.querySelector('#img_index')
const arrows = [
    document.querySelector('#arrow_left'),
    document.querySelector('#arrow_right')
]


const dropdown_items = document.querySelector('.dropdown_items')
const contact_info = document.querySelector('.contact_info')
const contact_method = dropdown_items.querySelectorAll('ul li')
const phone_number = document.querySelector('.phone_number')



close_button.onclick = () => {
    animateCloseGallery()
}


overlay.forEach((item, index) => {

    item.onclick = () => {



        animateGallery()


        var image_template = `
            <img src='${gallery_set[index]}' alt='southbound gallery'>
        `
        image_holder.innerHTML = image_template

        if (index < gallery_set.length / 2) {
            img_index.textContent = index + 1
        } else {
            img_index.textContent = (index + 1) - (gallery_set.length / 2)

        }

        //console.log()



        arrows[1].onclick = () => {
            imagesAnimation()


            if (index < (gallery_set.length / 2) - 1) {
                index++
                var image_template = `<img src='${gallery_set[index]}' alt='southbound gallery'> `
                image_holder.innerHTML = image_template
            }

            if (index < gallery_set.length / 2) {
                img_index.textContent = index + 1
            }

        }

        arrows[0].onclick = () => {


            if (index > 0) {
                imagesAnimation()
                index--
                var image_template = `<img src='${gallery_set[index]}' alt='southbound gallery'> `
                image_holder.innerHTML = image_template

                if (index < gallery_set.length / 2) {
                    img_index.textContent = index + 1
                } else {
                    img_index.textContent = (index + 1) - (gallery_set.length / 2)

                }

            }
            console.log(index)
        }

        var touchstartX = 0
        var touchendX = 0

        function Swipe() {

            imagesAnimation()

            if (touchendX > touchstartX) {
                if (index > 0) {
                    imagesAnimation()
                    index--
                    var image_template = `<img src='${gallery_set[index]}' alt='southbound gallery'> `
                    image_holder.innerHTML = image_template

                    if (index < gallery_set.length / 2) {
                        img_index.textContent = index + 1
                    } else {
                        img_index.textContent = (index + 1) - (gallery_set.length / 2)

                    }

                }
            }

            if (touchendX < touchstartX) {

                if (index < (gallery_set.length / 2) - 1) {
                    index++
                    var image_template = `<img src='${gallery_set[index]}' alt='southbound gallery'> `
                    image_holder.innerHTML = image_template
                }

                if (index < gallery_set.length / 2) {
                    img_index.textContent = index + 1
                }
            }

        }

        function touchStart(e) {
            touchstartX = e.changedTouches[0].screenX
        }

        function touchEnd(e) {
            touchendX = e.changedTouches[0].screenX
            Swipe()
        }

        image_holder.addEventListener('touchstart', touchStart)
        image_holder.addEventListener('touchend', touchEnd)

    }





})




document.addEventListener('click', function (e) {

    const target = e.target.closest('.dropdown')

    if (target) {
        Object.assign(dropdown_items.style, {
            height: 'max-content',
            overflow: 'auto',
            opacity: 1,
            display:'block'
        })
    } else {
        Object.assign(dropdown_items.style, {
            height: 0,
            overflow: 'hidden',
            opacity: 0,
            display:'none'
        })
    }
})

contact_method.forEach(item => {
    item.onclick = () => {
        //contact_info.textContent = item.innerText
      
        if (contact_info.textContent == 'Phone') {
            Object.assign(phone_number.style, {
                height: 'max-content',
                overflow: 'auto'
            })
        } else {
            Object.assign(phone_number.style, {
                height: 0,
                overflow: 'hidden'
            })
        }
    }
})

//fetch countries and country codes

 async function fetchCountries(){
    const codes_arrow = document.querySelector('.codes_arrow')
    const list_code = document.querySelector('.list_code')
   

    codes_arrow.onclick = ()=>{
        if(list_code.hidden){
            list_code.hidden = false
        }else{
            list_code.hidden = true
        }
    }

   

    const response = await fetch("https://gist.githubusercontent.com/devhammed/78cfbee0c36dfdaa4fce7e79c0d39208/raw/07df5ed443941c504c65e81c83e6313473409d4c/countries.json");
    const country_code = await response.json();
    console.log(country_code[0].flag);

    country_code.forEach(items=>{
        const country_code_container = document.createElement('div')
        country_code_container.classList = 'list_children'
        const template = `
        <div class='codes_parent'>
            <div class='flag'>${items.flag}</div>
            <div class='code'>${items.code}</div>
            <div class='code'>${items.dial_code}</div>
        </div>
        `
        country_code_container.innerHTML = template
        list_code.appendChild(country_code_container)

        const list_children = document.querySelectorAll('.list_children')

        list_children.forEach(item=>{
            const code_holder = document.querySelector('#code_holder')
            item.onclick =()=>{
                code_holder.innerHTML = item.textContent
                list_code.hidden = true
            }
        })

    })



   


 }

 fetchCountries()

//Privacy policy codes here...
const privacy_text = document.querySelector('.privacy_text')
const privacy_policy = document.querySelector('#policy_container')
const privacy_close_button = document.querySelector('.privacy_close_button')

privacy_text.onclick=()=>{
    privacy_policy.hidden = false
   Object.assign(privacy_policy.style,{
        visibility:'visible',
        
   })
}

privacy_close_button.onclick = ()=>{
    privacy_policy.hidden = true
}












