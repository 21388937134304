//modules here...
import { gsap } from 'gsap'

import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";

//images
//curate
import curate1 from './images/gallery/curated/curated_itenaries_1.webp'
import curate2 from './images/gallery/curated/curated_itenaries_2.webp'
import curate3 from './images/gallery/gallery_set/gallery_4.webp'

import curate4 from './images/gallery/curated/curated_itenaries_3.webp'

//reserve
import reserve1 from './images/gallery/reservation/reservation_1.webp'
import reserve2 from './images/gallery/reservation/reservation_2.webp'
import reserve3 from './images/gallery/gallery_set/gallery_12.webp'

//transport
import transport1 from './images/gallery/transport/transport_1.webp'
import transport2 from './images/gallery/transport/transport_2.webp'
import transport3 from './images/gallery/transport/transport_3.webp'

//private guide
import private_guide1 from './images/gallery/private_tour/private_tour_1.webp'
import private_guide2 from './images/gallery/private_tour/private_tour_2.webp'
import private_guide3 from './images/gallery/private_tour/private_tour_3.webp'

//photography
import photography1 from './images/gallery/photography/photography_1.webp'
import photography2 from './images/gallery/photography/photography2.webp'
import photography3 from './images/gallery/photography/photogaphy_3.webp'

//Dining
import dining1 from './images/gallery/dining/private_dining_1.webp'
import dining2 from './images/gallery/dining/private_dining_2.webp'
import dining3 from './images/gallery/dining/private_dining_3.webp'

//Exclusive
import exclusive1 from './images/gallery/exclusives/exclusive_1.webp'
import exclusive2 from './images/gallery/exclusives/eclusive_2.webp'
import exclusive3 from './images/gallery/exclusives/exclusive_3.webp'

//Shopping
import shopping1 from './images/gallery/shopping/shopping_1.webp'
import shopping2 from './images/gallery/shopping/shopping_2.webp'
import shopping3 from './images/gallery/shopping/shopping_3.webp'

//Conciege
import conciege1 from './images/gallery/conciege/concieg_1.webp'
import conciege2 from './images/gallery/conciege/conciege_2.webp'
import conciege3 from './images/gallery/conciege/conciege_3.webp'

//gallery images
import gallery1 from './images/gallery/gallery_set/gallery_1.webp'
import gallery2 from './images/gallery/gallery_set/gallery_2.webp'
import gallery3 from './images/gallery/gallery_set/gallery_3.webp'
import gallery4 from './images/gallery/gallery_set/gallery_5.webp'
import gallery5 from './images/gallery/gallery_set/gallery_6.webp'
import gallery6 from './images/gallery/gallery_set/gallery_7.webp'
import gallery7 from './images/gallery/gallery_set/gallery_8.webp'
import gallery8 from './images/gallery/gallery_set/gallery_9.webp'
import gallery9 from './images/gallery/gallery_set/gallery_10.webp'
import gallery10 from './images/gallery/gallery_set/gallery_11.webp'
import gallery11 from './images/gallery/gallery_set/gallery_12.webp'
import gallery12 from './images/gallery/gallery_set/gallery_13.webp'
import gallery14 from './images/gallery/gallery_set/gallery_14.webp'
import gallery15 from './images/gallery/gallery_set/gallery_15.webp'


gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);

//Variables here...
const splash_element = document.querySelector('.splash_element')
const tl = gsap.timeline()
const tl_2 = gsap.timeline()
const logo = document.querySelector('.logo')
const site_name = document.querySelector('.site_name')
const menu = document.querySelector('.menu')
const header_line_one = document.querySelector('.header_line_one')
const header_line_two = document.querySelector('.header_line_two')
const header_text2 = document.querySelector('.header_text2')
const banner_container = document.querySelector('.banner_container')
const banner = document.querySelector('.banner')
const body = document.querySelector('body')
const mobile_view = window.matchMedia('(max-width:600px)')
const caption = document.querySelector('.caption')
const splash_screen = document.querySelector('.splash_screen')
var svg_button = document.querySelector('#svg_button')
const gallery = document.querySelector('.gallery')
const image_holder = document.querySelector('.image_holder')
const caption_button = document.querySelector('.caption_button')
const up_button = document.querySelector('.up_button')
const service_list = document.querySelectorAll('.service_list')
const nav_bar = document.querySelector('.nav_bar')
const contact_container = document.querySelector('.contact_container')
const main_menu = document.querySelector('.main_menu')
const close_menu = document.querySelector('.close_menu')
const about_board = document.querySelector('.about_board')
const services = document.querySelector('.services')
const about_button = document.querySelector('#about')
const service_button = document.querySelector('#services')
const contact_button = document.querySelector('#contact')
const svg_text = document.querySelector('.svg_text')

const service_container = [
    document.querySelector('.service_container1'),
    document.querySelector('.service_container2'),
    document.querySelector('.service_container3'),
]

const service_description = [
    document.querySelector('.gradient_text h2'),
    document.querySelector('.gradient_text span')
]

const service_array = [
    {
        img1: curate1,
        img2: curate2,
        img3: curate3,
        caption: 'Curated Itineraries',
        description: 'Let us design a personalized itinerary tailored to your interests and preferences, ensuring you make the most of your time in South Africa.'
    },
    {
        img1: reserve1,
        img2: reserve2,
        img3: reserve3,
        caption: 'Reservation Assistance',
        description: ' From restaurant reservations to accommodation bookings, we handle all the details to ensure a seamless and stress-free travel experience.'
    },
    {
        img1: transport1,
        img2: transport2,
        img3: transport3,
        caption: 'Transportation Services',
        description: 'Enjoy convenient airport pick-up and drop-off, as well as ground transportation arrangements, so you can explore South Africa with ease.'

    },
    {
        img1: private_guide1,
        img2: private_guide2,
        img3: private_guide3,
        caption: 'Private Guided Tours and Excursions',
        description: 'Embark on private guided tours and excursions led by knowledgeable local guides, exploring the hidden gems and must-see attractions of South Africa.'
    },
    {
        img1: photography1,
        img2: photography2,
        img3: photography3,
        caption: 'Professional Photography / Videography Services',
        description: 'Capture your unforgettable moments with professional photography and videography services, ensuring you have lasting memories of your South African adventure.'
    },

    {
        img1: dining1,
        img2: dining2,
        img3: dining3,
        caption: 'Private Dining Experiences',
        description: 'Experience exquisite dining with private dining experiences at exclusive restaurants and venues, curated to your tastes and preferences.'
    },
    {
        img1: exclusive1,
        img2: exclusive2,
        img3: exclusive3,
        caption: 'Exclusive Access to Events or Attractions',
        description: 'Enjoy VIP access to exclusive events or attractions, allowing you to immerse yourself in the vibrant culture and entertainment of South Africa.'
    },
    {
        img1: shopping1,
        img2: shopping2,
        img3: shopping3,
        caption: 'Personal Shopping Services',
        description: 'Let us assist you with personal shopping, whether it’s sourcing unique souvenirs, boutique finds, or luxury goods, ensuring a memorable shopping experience.'

    },
    {
        img1: conciege1,
        img2: conciege2,
        img3: conciege3,
        caption: 'Dedicated Concierge Assistance',
        description: 'Our dedicated concierge team is available to provide personalized assistance throughout your journey, from itinerary adjustments to special requests, ensuring a seamless and unforgettable'

    }




]


function splashScreen() {
    var duration_one = 1;
    var splash_element_img = splash_element.querySelector('.splash_element img')
    tl.to(splash_element, {
        duration: duration_one,
        top: "0%",
        delay: .3,
        ease: "power4.out",


    })

    tl.to(splash_element_img, {
        delay: -.5,
        alpha: 1,
        y: 0
    })

    tl.to(splash_element, {
        delay: .8,
        y: -50,
        alpha: 0,

    })


    tl.to(splash_element, {
        top: "100%",
        ease: "power4.out",
        duration: duration_one,
        onComplete: () => {
            body.style.overflow = 'auto'
            try {
                splash_screen.style.display = "none"
            } catch (error) {

            }

        }
    })

    tl_2.to([logo, site_name, menu, header_line_one, header_line_two, header_text2], {
        delay: 2,
        duration: .5,
        alpha: 1,
        y: 0,
        stagger: .2
    })



    tl_2.to(banner_container, {
        alpha: 1,
        duration: 1,

    })

    if (mobile_view.matches) {

        gsap.set(banner, {
            'background-size': '250%',
        })

        tl_2.to(banner, {
            duration: 1.7,
            'background-size': '138%',
            ease: "power4.out",
            delay: -1
        })

        tl_2.to(caption, {
            alpha: 1,
            delay: -.5
        })



    } else {
        tl_2.to(banner, {
            duration: 1.7,
            'background-size': '100%',
            ease: "power4.out",
            delay: -1.5
        })

        tl_2.to(caption, {
            alpha: 1,
            delay: -1.5
        })
    }




}

function windowScroll() {
    //variables

    var caption_text = document.querySelector('.caption_text')


    window.addEventListener('scroll', () => {
        const banner_position = banner_container.offsetTop
        var scrollValue = document.scrollingElement.scrollTop

        if(scrollValue > 0){
            const whatsapp = document.querySelector('.whatsapp')
            whatsapp.style.display='block'
        }


        if ((scrollValue - banner_position) > banner_container.offsetHeight * 0.7) {
            svg_button.style.animation = 'dash 1.3s ease-out forwards'
            Object.assign(caption_text.style, {
                opacity: 1,
                transform: 'translateY(0px)'
            })
        }

    })

    window.addEventListener('scrollend', () => {
       

        Object.assign(up_button.style, {
            opacity: 1,
            visibility: 'visible'
        })

        
    })

}

svg_button.addEventListener('mouseover', e => {
    e.target.style.animation = 'dash2 1s ease-out forwards'
})

svg_button.addEventListener('mouseleave', e => {
    e.target.style.animation = 'dash  ease-out forwards'
})

const galleryCorousel = () => {
    var corousel_trey = document.querySelector('.corousel_trey')


    var galleryArray = [

        private_guide2,
        gallery2,
        gallery8,
        gallery9,
        gallery3,
        gallery4,
        gallery6,
        gallery7,
        curate1,
        curate3,
        reserve1,
        reserve2,
        transport1,
        private_guide3,
        curate2,
        photography3,
        dining1,
        dining3,
        gallery14,
        curate4,
        exclusive1,
        exclusive2,
        exclusive3,
        shopping3,
        conciege2,
        gallery15,

        private_guide2,
        gallery2,
        gallery8,
        gallery9,
        gallery3,
        gallery4,
        gallery6,
        gallery7,
        curate1,
        curate3,
        reserve1,
        reserve2,
        transport1,
        private_guide3,
        curate2,
        photography3,
        dining1,
        dining3,
        gallery14,
        curate4,
        exclusive1,
        exclusive2,
        exclusive3,
        shopping3,
        conciege2,
        gallery15,
    ]


    galleryArray.forEach(items => {
        var div = document.createElement('div')
        div.className = 'corousel_img'
        const template = `
        <div class='overlay'>
            <div class='zoom_icon'></div>
        </div>
        <img src=' ${items}' alt='southbound'>  
           
        `

        div.innerHTML = template

        corousel_trey.appendChild(div)
    })


}

const animateGallery = () => {
    gsap.fromTo(gallery, {
        alpha: 0
    }, {
        alpha: 1,
        display: 'block',
        delay: .2
    })
}

const animateCloseGallery = () => {
    gsap.fromTo(gallery, {
        alpha: 1
    }, {
        alpha: 0,
        display: 'none',
        delay: .2
    })
}

const imagesAnimation = () => {
    gsap.fromTo(image_holder, {

        alpha: 0,
    },
        {
            duration: 1,
            alpha: 1,

        })
}
//Smooth scroll to contact



caption_button.onclick = () => {
    gsap.to(window, {
        scrollTo: contact_container,
        duration: 3,
        ease: 'power4.out'
    })
}

svg_text.onclick = () => {
    gsap.to(window, {
        scrollTo: contact_container,
        duration: 3,
        ease: 'power4.out'
    })
}

up_button.onclick = () => {
    gsap.to(window, {
        scrollTo: nav_bar,
        duration: 2,
        ease: 'power4.out'
    })
}

//Services codes here...
const serve_trey = document.querySelector('.serve_trey')


service_list.forEach((item, index) => {


    item.onclick = () => {
        const service_tl = gsap.timeline()

        service_tl.to(service_container, {
            x: -30,
            stagger: .2,
            alpha: 0,
            duration: .2

        })

        service_tl.to(service_container, {
            x: 30,
            duration: .1,
            onComplete: () => {

                service_array.forEach((items, indexes) => {
                    if (index === indexes) {
                        service_container[0].style.backgroundImage = `url(${items.img1})`
                        service_container[1].style.backgroundImage = `url(${items.img2})`
                        service_container[2].style.backgroundImage = `url(${items.img3})`
                        service_description[0].textContent = items.caption
                        service_description[1].textContent = items.description
                    }
                })

            }
        })

        service_tl.to(service_container, {
            x: 0,
            stagger: .2,
            alpha: 1

        })



        service_list.forEach(items => {
            items.classList.remove('selected')
        })

        item.classList.add('selected')

    }

    if(mobile_view.matches){
        const serve_img = document.querySelector('.serve_img')
        const serve_img1 = document.querySelector('.serve_img1')
        const serve_img2 = document.querySelector('.serve_img2')
        const mobile_caption = document.querySelector('.mobile_caption')
        const mobile_description_content = document.querySelector('.mobile_description_content')
        

        item.onclick=()=>{
            serve_trey.style.transform = 'translateX(-50%)'

            const service_tl = gsap.timeline()

        service_tl.to([serve_img,serve_img1,serve_img2], {
            x: -30,
            stagger:.2,
            alpha: 0,
            duration:.2

        })

        service_tl.to([serve_img,serve_img1,serve_img2], {
            x: 30,
            duration: .1,
            onComplete: () => {

                service_array.forEach((items, indexes) => {
                    if (index === indexes) {
                        serve_img.style.backgroundImage = `url(${items.img1})`
                        serve_img1.style.backgroundImage = `url(${items.img2})`
                        serve_img2.style.backgroundImage = `url(${items.img3})`
                        mobile_caption.textContent = items.caption
                        mobile_description_content.textContent = items.description
                    }
                })

            }
        })

        service_tl.to([serve_img,serve_img1,serve_img2], {
            x: 0,
            stagger: .2,
            alpha: 1

        })

        service_list.forEach(items => {
            items.classList.remove('selected')
        })

        item.classList.add('selected')

        }
    }

})

//Back button at services section
const back_arrow = document.querySelector('.back_arrow')

back_arrow.onclick = ()=>{
    serve_trey.style.transform = 'translateX(0%)'
}


menu.onclick = () => {
    gsap.to(main_menu, {
        top: 0,
        ease: 'power3.out',
        duration: 1
    })
}

close_menu.onclick = () => {
    gsap.to(main_menu, {
        top: '-100%',
        ease: 'power3.out',
        duration: 1
    })
}

about_button.onclick = () => {
    gsap.to(window, {
        scrollTo: about_board,
        duration: 3,
        ease: 'power4.out'
    })

    gsap.to(main_menu, {
        top: '-100%',
        ease: 'power3.out',
        duration: 3
    })
}

service_button.onclick = () => {
    gsap.to(window, {
        scrollTo: services,
        duration: 3,
        ease: 'power4.out'
    })

    gsap.to(main_menu, {
        top: '-100%',
        ease: 'power3.out',
        duration: 3
    })
}

contact_button.onclick = () => {
    gsap.to(window, {
        scrollTo: contact_container,
        duration: 3,
        ease: 'power4.out'
    })

    gsap.to(main_menu, {
        top: '-100%',
        ease: 'power3.out',
        duration: 3
    })
}

//Exports Here...
export {
    splashScreen,
    windowScroll,
    galleryCorousel,
    gallery,
    animateGallery,
    animateCloseGallery,
    image_holder,
    imagesAnimation
}


